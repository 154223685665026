
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from "react";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { GetServerSideProps } from "next";
import { NextSeo } from "next-seo";
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
import MainBanner from "@/components/MainPage/MainBanner";
import RecentPosts from "@/components/MainPage/RecentPosts";
import SubscribeBlock from "@/components/SubscribeBlock";
import PickPosts from "@/components/MainPage/PickPosts";
import TrendingPosts from "@/components/MainPage/TrendingPosts";
import CategoryPosts from "@/components/MainPage/CategoryPosts";
import { handleError } from "@/helpers/handleError";
import { CategoryData } from "@/Types/types";
import { GET_HEADER_DATA } from "@/graphql/headerQueries";
import { GET_HOMEPAGE_DATA } from "@/graphql/homepageQueries";
import { GET_CATEGORY_POSTS } from "@/graphql/categoryQueries";
import { ONE_DAY_IN_SECONDS, ONE_MINUTE_IN_SECONDS } from "@/constants/timeConstants";
export default function Home(props: any) {
    const categories: Record<"attributes", CategoryData>[] = props.categories.data;
    const filteredCategories = categories.filter((item) => item?.attributes?.categoryPosts?.data?.length);
    return (<>
      <HeaderMenu cookies={props.cookies.data} menu={props.navbar.data?.attributes.header_links}/>
      <NextSeo title={props.homepage.data.attributes.metaTitle} description={props.homepage.data.attributes.metaDescription}/>
      {Boolean(props.homepage.data.attributes.articles.data.length) && (<MainBanner data={props.homepage.data.attributes.articles.data}/>)}
      <RecentPosts data={props.fourLatest.data} sponsorImg={props.homepage.data.attributes.sponsorImg.data.attributes.url} sponsorLink={props.homepage.data.attributes.sponsorLink} topics={categories}/>
      {Boolean(props.top.data.length) && <PickPosts item={props.top.data}/>}
      {filteredCategories.map((item, index) => (<React.Fragment key={index}>
            <CategoryPosts category={item?.attributes?.title} url={item?.attributes?.slug} articles={item?.attributes?.categoryPosts?.data}/>
            {(index === filteredCategories.length % 2 ||
                filteredCategories.length === 1) && (<>
                  {Boolean(props.trending.data.length) && (<TrendingPosts item={props.trending.data}/>)}
                </>)}
          </React.Fragment>))}
      {Boolean(!filteredCategories.length) && (<>
            {Boolean(props.trending.data.length) && (<TrendingPosts item={props.trending.data}/>)}
          </>)}
      <SubscribeBlock />
      <Footer menu={props.footer[0].items}/>
    </>);
}
const getServerSideProps: GetServerSideProps = async ({ res }) => {
    try {
        res.setHeader("Cache-Control", `public, s-maxage=${ONE_DAY_IN_SECONDS}, immutable`);
        const client = new ApolloClient({
            uri: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
            cache: new InMemoryCache()
        });
        const { data: headerData } = await client.query({
            query: GET_HEADER_DATA
        });
        const { data: homepageData } = await client.query({
            query: GET_HOMEPAGE_DATA
        });
        const { data: categoryArticlesData } = await client.query({
            query: GET_CATEGORY_POSTS
        });
        return {
            props: {
                ...headerData,
                ...homepageData,
                ...categoryArticlesData
            }
        };
    }
    catch (error) {
        handleError(error, "Index getServerSideProps");
        console.error(error);
        return {
            notFound: true
        };
    }
};

    async function __Next_Translate__getServerSideProps__19485113681__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19485113681__ as getServerSideProps }
  